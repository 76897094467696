
















































.footer {
  width: 1240px;

  .content {
    display: flex;
    padding: 0 0 0 20px;
    margin: 50px 0 100px 0;
    width: 80%;
    text-transform: uppercase;

    &.mobile {
      display: none;
      @media all and (max-width: 480px) {
        display: flex;
      }
    }

    &.default {
      @media all and (max-width: 480px) {
        display: none;
      }
    }

    @media all and (max-width: 480px) {
      flex-wrap: wrap;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 480px) {
        width: 50%;
      }

      &:not(:first-child) {
        margin-left: 160px;
        @media all and (max-width: 480px) {
          margin: 0;
        }
      }
      &:last-child {
        margin-left: auto;
      }

      li {
        margin: 0;
        &::before {
          display: none;
        }
        a {
          color: #fff;
          font-weight: normal;
          &:hover {
          }
        }
      }
    }

    @media all and (max-width: 1000px) {
      ul:nth-child(2) {
        margin-left: 40px;
      }
    }

    @media all and (max-width: 700px) {
      width: 100%;

      ul:nth-child(2) {
        margin-left: 20px;
      }
      ul:last-child {
        margin: 0;
        margin-left: auto;
      }
    }

  }
}
