














































































.product {
  .placeholder {
    text-align: center;
    font-size: 2rem;
  }

  h2.placeholder {
    text-align: center;
  }

  .product-content {
    display: flex;
    flex-direction: row;

    @media all and (max-width: 768px) {
      flex-direction: column;
    }

    .image {
      width: 560px;
      max-width: 560px;
      flex: 1 0 auto;
      img {
        width: 100%;
      }
      @media all and (max-width: 560px) {
        width: 100%;
      }
    }
    .data {
      padding-left: 30px;
      @media all and (max-width: 768px) {
        padding-left: 0;
      }

      p {
        margin: 0 0 20px 0;
      }
      ul {
        margin: 0;
        padding: 0;

        li {
          padding: 0;
          margin: 4px 0;

          &:before {
            content: none;
          }
        }
      }
    }
  }

  .product-gallery {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    img {
      width: 560px;
      max-width: 560px;
      height: auto;
      margin-bottom: 30px;
      &:nth-child(odd) {
        margin-right: 30px;
      }

      @media all and (max-width: 560px) {
        width: 100%;
      }
    }
  }
}
