


























































































































































.products-view {
  .categories-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media all and (max-width: 640px) {
      justify-content: center;
    }
    @media all and (max-width: 400px) {
      justify-content: space-evenly;
    }

    .cat {
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @media all and (max-width: 768px) {
        margin-right: 20px;
      }

      @media all and (max-width: 640px) {
        margin-right: 10px;
      }

      @media all and (max-width: 400px) {
        margin-right: 0;
      }

      .icon {
        width: 260px;
        height: 200px;
        background: var(--red);
        transition: background 0.2s ease-in-out;
        will-change: background;

        @media all and (max-width: 1070px) {
          width: 200px;
          height: 160px;
        }

        @media all and (max-width: 768px) {
          width: 180px;
          height: 150px;
          img {
            width: 100%;
            height: auto;
          }
        }

        @media all and (max-width: 720px) {
          width: 150px;
          height: 100px;
        }

        @media all and (max-width: 700px) {
          width: 130px;
          height: 100px;
        }
      }

      .name {
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 5px;
        font-weight: 500;
        color: var(--brown);
        @media all and (max-width: 720px) {
          font-size: 16px;
        }
        @media all and (max-width: 700px) {
          font-size: 14px;
        }
      }

      &.active, &:hover {
        .icon {
          background: grey;
        }
      }
    }
  }
  
  .products-container {
    .product {
      margin-bottom: 40px;

      .title {
        color: var(--brown);
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
      }
      ::v-deep {
        .wp-block-columns.has-2-columns {
          .wp-block-column {
            &:first-child {
              width: 560px;
              img {
                width: 100%;
                height: auto;
              }
            }
            &:nth-child(2) {
              ul {
                padding: 0;
                li {
                  margin-bottom: 5px;

                  &:before {
                    display: none;
                  }
                }
              }
              p {
                margin: 0 0 10px 0;
              }
            }
          }
        }
      }
    }
  }

  .product-nav-container {
    border-top: 2px solid var(--red);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    margin: 0 0 60px 0;

    &.bottom {
      border: none;
    }

    .head {
      font-weight: 500;
      font-size: 20px;
      color: var(--brown);
      text-transform: uppercase;
      margin: 0 0 10px 0;
    }

    .inquire {
      margin: 0 0 20px 0;
    }

  }

  .nav-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 400px) {
      justify-content: space-between;
      flex-direction: column-reverse;
    }

    .cat-nav-btn {
      padding: 5px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        color: var(--red);
        font-size: 2em;
        display: inline-block;
        margin: 0 10px;
      }

      @media all and (max-width: 400px) {
        margin: 0;
        .icon {
          margin: 0 2px;
        }
      }
    }
  }

  .list-enter-active, .list-leave-active {
    transition: all 0.3s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
