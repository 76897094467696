
































.menu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  /*background: rgba(98, 103, 112, 0.9);*/
  top: 0;
  left: 0;

  .menu {
    position: relative;
    width: 100%;
    height: 100%;
    /*margin: 50px auto 0 auto;*/

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      padding: 10px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      background: rgba(98, 103, 112, 0.9);
    }

    .menu-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 1px 1px 10px #00000038;
      /*background: rgba(255, 255, 255, 1);*/
      /*background: rgba(98, 103, 112, 0.9);*/
      background: var(--grey-menu);

      ul {
        margin: 0;
        padding: 0;
        font-size: 25px;
        list-style-type: none;
        
        li {
          text-align: center;
          margin: 0 0 5px 0;
          &::before {
            display: none;
          }
          a {
            text-transform: uppercase;
            color: #fff;
            font-weight: 300;
            &:hover {
              color: var(--red);
            }
          }

          &:nth-last-child(4) {
            height: 80px;
          }
        }
      }
    }
  }
}
