















































.references-view {
  .references-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .reference {
      width: 560px;
      margin-bottom: 20px;

      @media all and (max-width: 600px) {
        width: 100%;
      }

      .title {
        width: 100%;
        font-size: 20px;
      }

      .gallery-container {
        width: 100%;
      }
    }
  }
}
