




















































































































.base-page {
  @media all and (max-width: 1100px) {
    width: 100%;
  }
  ::v-deep {
    h2 {
      /*font-size: 20px;*/
      font-size: 22px;
      font-weight: bold;
    }
    p, li {
      /*line-height: 24px;*/
      /*font-size: 16px;*/
      font-size: 18px;
      line-height: 27px;
    }
    .wp-block-image {
      figure {
        figcaption {
          text-transform: uppercase;
          font-weight: bold;
          margin: 20px 0;
          font-size: 18px;
          color: var(--brown);
        }
      }
    }

    .under-construction {
      color: var(--red) !important;
      margin-top: 40px;
    }
  }
}
