

























































































.multipage-view {
  .page-nav-container {
    display: flex;
    flex-direction: row;

    .page {
      text-align: center;
      cursor: pointer;
      flex: 1 1;

      @media all and (max-width: 768px) {
        &:not(:first-child), &:not(:last-child) {
          margin: 0 10px;
        }
      }

      img {
        transition: filter 0.3s ease-in-out;
      }

      &.active {
        img {
          filter: grayscale(100%);
        }
      }
      
      .title {
        color: var(--brown);
        font-size: 1.5rem;
        font-weight: 500;
        margin: 10px 0;
      }

      .excerpt {
        line-height: 1.5rem;
      }
    }

    @media all and (max-width: 600px) {
      flex-direction: column;
      flex-wrap: wrap;

      .page {
        display: flex;
        flex: 1 0;
        flex-wrap: wrap;
        margin-bottom: 20px !important;
        align-items: center;

        .thumb {
          width: 60px;
        }
        .title {
          width: calc(100% - 60px);
          text-align: left;
          padding-left: 20px;
        }
        .excerpt {
          text-align: left;
          margin-top: 5px;
        }
      }
    }

  }
}
